import React from 'react'

import { CheckoutLayout, CheckoutStepShipping } from '../../components'

const CheckoutStepShippingTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <CheckoutLayout lang={lang} switcher={page}>
      <CheckoutStepShipping lang={lang} />
    </CheckoutLayout>
  )
}

export default CheckoutStepShippingTemplate
